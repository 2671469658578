import React from 'react'
import Layout from '../layouts'
import style from '../layouts/style.module.sass'
import LinkTop from '../components/LinkTop'
import Helmet from 'react-helmet'

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: '', email: '', message: '', submit: 'disabled' }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    if (
      this.state.name &&
      this.state.email &&
      this.state.message &&
      this.state.name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.message.length > 0
    ) {
      this.setState({ submit: '' })
      //document.getElementById("submit").removeAttribute("disabled")
    } else {
      this.setState({ submit: 'disabled' })
      //document.getElementById("submit").setAttribute("disabled","disabled")
    }
    //console.log(this.state)
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="Contact | webdevelopment.jp"
          meta={[
            {
              name: 'description',
              content:
                'webdevelopment.jpへのお問い合わせはこちらから承ります。',
            },
          ]}
        />
        <h1>Contact</h1>

        <p>お問い合わせは以下のフォームより承ります。</p>

        <form
          name="contact"
          method="post"
          action="/thanks"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className={style.contactForm}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <input
            type="text"
            name="name"
            placeholder="あなたの名前を入力してください"
            value={this.state.name}
            className={style.contactFormName}
            onChange={this.handleChange}
            onBlur={this.handleChange}
          />
          <input
            type="text"
            name="email"
            placeholder="メールアドレス"
            value={this.state.email}
            className={style.contactFormEmail}
            onChange={this.handleChange}
            onBlur={this.handleChange}
          />
          <textarea
            name="message"
            placeholder="お問い合わせ内容を入力してください"
            value={this.state.message}
            className={style.contactFormTextarea}
            onChange={this.handleChange}
            onBlur={this.handleChange}
          />
          <div data-netlify-recaptcha />
          <button
            type="submit"
            className={style.contactFormSubmit}
            disabled={this.state.submit}
            id="submit"
          >
            送信
          </button>
        </form>
        <LinkTop scrollStepInPx="50" delayInMs="16.66" />
      </Layout>
    )
  }
}
